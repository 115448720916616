<template>
    <div class="footer-container">
        <div class="footer-container1" :style="'background-color:' + $config.dados.corprimaria + ' !important'">
            <div class="footer-container2">
                <div class="footer-container3">
                    <v-list color="transparent" dark dense class="footer-container34">
                        <v-list-item-title style="max-height: 40px !important">
                            <h3 v-once style="color: white">{{ $t_('politicas') }}</h3>
                        </v-list-item-title>
                        <v-list-item v-for="(item, index) in politicas" :key="index" @click="getLink(item)"
                            v-once style="max-height: 40px !important" :class="$config.mobile() ? 'text-center' : 'pl-0'">
                            <div>{{ item.title }}</div>
                        </v-list-item>
                    </v-list>
                </div>
                <div class="footer-container3">
                    <v-list color="transparent" dark dense style="width: 184.125px!important;" class="footer-container34">
                        <v-list-item-title style="max-height: 40px !important">
                            <h3 v-once style="color: white">{{ $t_('mapa_site') }}</h3>
                        </v-list-item-title>
                        <v-list-item v-for="(item, index) in mapa" :key="index" :class="$config.mobile() ? 'text-center' : 'pl-0'"
                            @click="getLink(item)" style="max-height: 40px !important; padding: 0">
                            <div>{{ item.title }}</div>
                        </v-list-item>
                    </v-list>
                </div>
                <div class="footer-container5">
                    <div style="width: 184.125px!important;">
                        <img alt="logo" :src="$config.dados.logowhite" class="footer-image3" />
                    </div>
                </div>
            </div>
        </div>
        <img :alt="image_alt" :src="image_src" class="footer-image" />
        <div class="footer-container6">
            <div class="footer-container7">
                <span class="footer-text13">
                    {{$t_('copyright')}}
                </span>
                <span class="footer-text13">{{$t_('powered_by')}}</span>
                <div class="footer-container8">
                    <a href="https://eleventickets.com/" target="_blank"><img alt="Eleven tickets" :src="image_src1" class="footer-image1" /></a>
                    <a href="https://www.imply.com/pt/" target="_blank"><img alt="Imply" :src="image_src2" class="footer-image2" /></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Footer',
        props: {
            rootClassName: String,
            image_alt1: {
                type: String,
                default: 'image',
            },
            image_src2: {
                type: String,
                default: '/playground_assets/imply122x30-1200h.png',
            },
            image_alt2: {
                type: String,
                default: 'image',
            },
            image_alt: {
                type: String,
                default: 'image',
            },
            image_src1: {
                type: String,
                default: '/playground_assets/eleventickets122x30-1200h.png',
            },
            image_src: {
                type: String,
                default: '/playground_assets/padrao-1000h.png',
            },
            image_alt3: {
                type: String,
                default: 'image',
            },
        },

        data() {
            return {
                rawqn4y: ' ',
                politicas: [
                    {
                        id: 1,
                        title: this.$t_('politica_privacidade'),
                        icon: '',
                        name: 'Politicas',
                        link: 'privacidade'
                    },
                    {
                        id: 2,
                        title: this.$t_('politica_cookies'),
                        icon: '',
                        name: 'Politicas',
                        link: 'cookies'
                    },
                    {
                        id: 3,
                        title: this.$t_('termo_uso'),
                        icon: '',
                        name: 'Politicas',
                        link: 'termo-uso'
                    },
                    {
                        id: 4,
                        title: this.$t_('termo_compra'),
                        icon: '',
                        name: 'Politicas',
                        link: 'termos-compra'
                    },
                ],

                mapa: [
                    {
                        title: this.$t_('home_lower'),
                        icon: '',
                        name: 'Home',
                        link: ''
                    },
                    {
                        title: this.$t_('perguntas_frequentes'),
                        icon: '',
                        name: 'Informacoes',
                        link: 'perguntas-frequentes'
                    },
                ],
                sac: [
                
                ]

            }
        },
        methods: {
            getLink(item) {
                if(item.link == 'sac'){
                    this.$sac.startFlowSac()
                    return
                }
                this.$router.push({name: item.name, params: {pagina: item.link}})
            },
        },
        mounted(){
            setTimeout(() => {
                if(window.imply && typeof window.imply.SAC != 'undefined'){
                    this.mapa.push({
                        title: 'SAC',
                        icon: '',
                        name: 'sac',
                        link: 'sac'
                    })
                }
            }, 1500)
        }
    }
</script>

<style scoped>
    .footer-container {
        width: 100%;
        display: flex;
        position: relative;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .footer-container1 {
        flex: 0 0 auto;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        background-color: var(--dl-color-principal);
    }

    .footer-container2 {
        flex: 0 0 auto;
        width: 991px;
        display: flex;
        padding: 15px;
        align-items: flex-start;
        flex-direction: row;
        justify-content: center;
    }

    .footer-container3 {
        flex: 0 0 auto;
        width: 33.33%;
        display: flex;
        margin-top: 5px;
        align-items: left;
        margin-bottom: 5px;
        flex-direction: column;
        justify-content: center;
    }

    .footer-text {
        color: var(--dl-color-gray-white);
        font-style: normal;
        font-weight: 400;
    }

    .footer-text01 {
        color: var(--dl-color-gray-white);
        font-size: 14px;
        padding-top: 8px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 8px;
    }

    .footer-text03 {
        color: var(--dl-color-gray-white);
        font-size: 14px;
        padding-top: 8px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 8px;
    }

    .footer-container4 {
        flex: 0 0 auto;
        width: 33.33%;
        display: flex;
        margin-top: 15px;
        align-items: flex-start;
        margin-bottom: 15px;
        flex-direction: column;
        justify-content: flex-start;
    }

    .footer-text05 {
        color: var(--dl-color-gray-white);
        font-style: normal;
        font-weight: 400;
    }

    .footer-text06 {
        color: var(--dl-color-gray-white);
        font-size: 14px;
        padding-top: 8px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 8px;
    }

    .footer-text08 {
        color: var(--dl-color-gray-white);
        font-size: 14px;
        padding-top: 8px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 8px;
    }

    .footer-text10 {
        color: var(--dl-color-gray-white);
        font-size: 14px;
        padding-top: 8px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 8px;
    }

    .footer-container5 {
        flex: 0 0 auto;
        width: 33.33%;
        display: flex;
        margin-top: 15px;
        align-items: flex-start;
        margin-bottom: 15px;
        flex-direction: column;
        justify-content: flex-start;
    }

    .footer-text12 {
        color: var(--dl-color-gray-white);
        font-style: normal;
        font-weight: 400;
    }

    .footer-textinput {
        color: var(--dl-color-gray-black);
        height: 35px;
        margin-top: 5px;
        border-color: var(--dl-color-gray-900);
        margin-bottom: 5px;
    }

    .footer-button {
        color: var(--dl-color-gray-white);
        font-size: 12px;
        text-align: center;
        transition: 0.3s;
        border-color: #000000;
        border-width: 0px;
        text-transform: uppercase;
        background-color: #b5b5b5;
    }

    .footer-button:hover {
        background-color: var(--dl-color-gray-black);
    }

    .footer-image {
        width: 100%;
        height: 4px;
        object-fit: cover;
    }

    .footer-container6 {
        flex: 0 0 auto;
        width: 100%;
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        justify-content: center;
        background-color: var(--dl-color-gray-black);
    }

    .footer-container7 {
        flex: 0 0 auto;
        width: 991px;
        display: flex;
        padding: 15px;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 75px;
    }

    .footer-text13 {
        color: var(--dl-color-gray-700);
        font-size: 12px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .footer-container8 {
        flex: 0 0 auto;
        width: 100%;
        display: flex;
        align-items: center;
        padding-top: 15px;
        flex-direction: row;
        padding-bottom: 15px;
        justify-content: center;
    }

    .footer-image1 {
        width: 100px;
        object-fit: cover;
        margin-left: 5px;
        margin-right: 5px;
    }

    .footer-image2 {
        width: 100px;
        object-fit: cover;
        margin-left: 5px;
        margin-right: 5px;
    }

    .footer-image3 {
        width: 150px;
        object-fit: cover;
        margin-left: 17px;
        margin-right: 5px;
    }

    @media(max-width: 991px) {
        .footer-container2 {
            width: 100%;
        }

        .footer-container7 {
            width: 100%;
        }

        .footer-container34 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    @media(max-width: 767px) {
        .footer-container2 {
            flex-direction: column;
        }

        .footer-container3 {
            width: 100%;
            display: flex;
            align-self: center;
            align-items: center;
            justify-content: center;
        }

        .footer-container34 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .footer-container4 {
            width: 100%;
            align-items: center;
        }

        .footer-container5 {
            width: 100%;
            align-items: center;
        }

        .footer-button {
            width: auto;
            text-align: center;
        }
    }
</style>